<template>
  <main class="container pb-16 pb-lg-32 px-lg-32">
    <Table
      rowKey="id"
      :isLoading="$store.getters['tasks/isFetching'](params)"
      :columns="columns"
      :rows="$store.getters['tasks/get'](params)"
    >
      <template #bottom>
        <div class="border-top p-16" v-if="meta && meta.last_page > 1">
          <Pagination :current="meta.current_page" :last="meta.last_page" />
        </div>
      </template>
    </Table>
  </main>
</template>

<script>
import merge from 'lodash/merge';

import Table from '../../../components/table/Table.vue';
import Pagination from '../../../components/table/Pagination.vue';
import TableActions from '../../../components/student/task/TableActions.vue';

export default {
  components: { Table, Pagination },

  metaInfo: { title: 'Tugas' },

  computed: {
    params() {
      return merge({ sort: { id: 'desc' } }, this.$route.query);
    },

    columns() {
      return [
        { key: 'subject.name', text: 'Pelajaran' },
        { key: 'title', text: 'Judul' },
        {
          key: 'due_time',
          text: 'Batas Waktu',
          toHTML: (row) =>
            row.isPastDue
              ? `<span class="text-danger">
                  ${row.dueTimeJS.format('YYYY-MM-DD HH:mm')}
                </span>`
              : row.due_time &&
                `<span class="text-success">
                  ${row.dueTimeJS.format('YYYY-MM-DD HH:mm')}
                </span>`,
        },
        {
          key: 'answered',
          text: 'Status',
          toHTML: (row) =>
            row.answers.length
              ? '<span class="text-success">Dijawab</span>'
              : '<span class="text-danger">Belum Dijawab</span>',
        },
        {
          key: 'answers[0].score',
          text: 'Nilai',
          toHTML: (row) => {
            const score = row.get('answers[0].score');
            return score && `${score}<small class="text-muted">/100</small>`;
          },
        },
        {
          key: 'action',
          component: TableActions,
          props: { class: 'text-end' },
        },
      ];
    },

    meta() {
      return this.$store.getters['tasks/getMeta'](this.params);
    },
  },

  methods: {
    fetch() {
      this.$store.dispatch('tasks/maybeFetch', this.params);
    },
  },

  beforeMount() {
    this.fetch();
  },

  watch: {
    '$route.query': {
      handler() {
        this.fetch();
      },
      deep: true,
    },
  },
};
</script>
